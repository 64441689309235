<messages>["./WDRP"]</messages>
<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <name-value-block :items="basicData"/>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="data.trail"
            :items-per-page="-1"
            hide-default-footer
            class="elevation-1"
            :headers="headers"
            :no-data-text="$t ('label.empty')">
            <template #item="props">
              <tr>
                <td class="text-left">
                  {{ props.item.emailId }}
                </td>
                <td>
                  {{ props.item.purpose }}
                </td>
                <td>
                  {{ formatDate (props.item.dueDate) }}
                </td>
                <td>
                  {{ props.item.confirmation }}
                </td>
                <td>
                  <email-dialog :id="props.item.emailId">
                    <template #activator="{ on }">
                      <a
                        v-t="'label.details'"
                        href=""
                        v-on="on"/>
                    </template>
                  </email-dialog>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import EmailDialog from '@/app/core/components/Email/EmailViewDialog'

  import {EmptyMark} from '@/app/utils/string'

  export default {

    components: {
      NameValueBlock,
      EmailDialog
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    computed: {
      basicData () {
        return [
          {
            name: this.$t ('label.properties.objectName'),
            value: this.data.objectName ? this.data.objectName : EmptyMark
          },
          ...[
            'usage',
            'objectType',
            'objectSource',
            'state',
            'delegation'
          ].map ((val) => ({
            name: this.$t (`label.properties.${val}`),
            value: this.data[val] ? this.tryTranslation (this.data[val]) : EmptyMark
          })),

          ...[
            'dueDate',
            'expires'
          ].map ((val) => ({
            name: this.$t (`label.properties.${val}`),
            value: this.data[val] ? this.formatDate (this.data[val]) : EmptyMark
          }))
        ]
      },

      headers () {
        return [
          {
            text: this.$t ('label.properties.id'),
            value: 'emailId'
          },
          {
            text: this.$t ('label.properties.purpose'),
            value: 'purpose',
            sortable: false
          },
          {
            text: this.$t ('label.properties.dueDate'),
            value: 'dueDate'
          },
          {
            text: this.$t ('label.properties.confirmation'),
            value: 'confirmation',
            sortable: false
          },
          {
            text: this.$t ('label.properties.access'),
            value: 'emailId',
            sortable: false
          }
        ]
      }
    },

    methods: {
      tryTranslation (val) {
        return this.$t (`label.values.${val.toLowerCase ()}`) === `label.values.${val.toLowerCase ()}`
          ? val
          : this.$t (`label.values.${val.toLowerCase ()}`)
      }
    }
  }
</script>
