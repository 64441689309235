<messages>["./Email"]</messages>
<template>
  <base-dialog
    v-model="dialog"
    mw0
    scrollable
    close-on-esc>
    <template #activator="{ on }">
      <div
        v-on="on"
        @click.prevent="loadEmail ()">
        <slot name="activator"/>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <div>
          <div
            v-t="'label.title'"
            class="text-h5"/>
          <div class="cgwng-subheading">
            {{ $t ('label.subtitle', { id }) }}
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-slide-y-transition mode="out-in">
          <v-row v-if="isLoading" key="loading">
            <v-col cols="12">
              <v-progress-linear indeterminate/>
            </v-col>
          </v-row>
          <v-row v-else-if="error" key="error">
            <v-col cols="12">
              <v-alert type="error">
                {{ $t ('label.error', { id }) }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-else
            key="content">
            <v-col cols="12">
              <name-value-block :items="meta"/>
            </v-col>
            <v-col cols="12">
              <email-download-btn :id="id"/>
            </v-col>
          </v-row>
        </v-slide-y-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.close'"
          text
          @click.native="dialog=false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'

  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import BaseDialog from '@/app/core/components/BaseDialog'

  import EmailDownloadBtn from './EmailDownloadBtn'

  export default {
    name: 'EmailDialog',

    components: {
      BaseDialog,
      NameValueBlock,
      EmailDownloadBtn
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        dialog: false,
        isLoading: false,
        error: false,
        email: null
      }
    },

    computed: {
      meta () {
        if (!this.email) {
          return []
        }
        return [...[
                  'purpose',
                  'to',
                  'subject'
                ].map ((key) => {
                  return {
                    name: this.$t (`label.meta.${key}`),
                    value: this.email[key]
                      ? this.email[key]
                      : EmptyMark
                  }
                }),
                {
                  name: this.$t ('label.meta.sentDate'),
                  value: this.email.sentDate
                    ? this.formatDate (this.email.sentDate)
                    : EmptyMark
                }
        ]
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadEmail () {
        if (!this.email) {
          this.isLoading = true
          this.fetchData ({
            op: 'email/load',

            params: {
              id: this.id
            },

            cb: data => {
              this.email = data.email
            },
            cbError: () => {
              this.error = true
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      }
    }
  }
</script>
