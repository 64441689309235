<template>
  <base-layout mw1>
    <v-col cols="12">
      <wdrp-search-card :loading="isLoading" @reset="reset" @submit="submit"/>
    </v-col>
    <v-slide-y-transition>
      <v-col v-if="showAuditTrail" cols="12">
        <wdrp-audit-trail-card :loading="isLoading" :data="loadedData" :error="error"/>
      </v-col>
    </v-slide-y-transition>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import WdrpSearchCard from './components/WDRPSearchCard'
  import WdrpAuditTrailCard from './components/WDRPAuditTrailCard'

  export default {
    components: {
      BaseLayout,
      WdrpSearchCard,
      WdrpAuditTrailCard
    },

    data () {
      return {
        isLoading: false,
        error: false,
        loadedData: null
      }
    },

    computed: {
      showAuditTrail () {
        return this.isLoading || this.error || this.loadedData
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reset () {
        this.error = false
        this.loadedData = null
      },

      submit (params) {
        this.isLoading = true
        this.loadedData = null
        this.fetchData ({
          op: 'services/query-audit-trail',
          params: params,
          cb: data => {
            this.loadedData = data.trackings
          },
          cbError: (data) => {
            this.error = true
          },

          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
