<template>
  <v-btn
    v-t="'general.button.download'"
    :href="link"/>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: {
      id: {
        type: Number,
        required: true
      }
    },

    computed: {
      ...mapGetters ({
        operationLink: 'request/operationLink'
      }),
      link () {
        return this.operationLink ({
          op: 'email/download',
          params: {
            id: this.id
          }
        })
      }
    }
  }
</script>
