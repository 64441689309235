<messages>["./WDRP"]</messages>
<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <div
          v-t="'label.search'"
          class="text-h5"/>
        <div
          v-t="'label.subSearch'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <form @submit.prevent="submit">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <radio-group
              v-model="queryType"
              column
              :label="`${$t('label.queryType')}:`"
              :option-values="queryOptions"
              :option-labels="queryOptionsLables"/>
          </v-col>
          <v-col cols="12" sm="6">
            <radio-group
              v-model="objectType"
              column
              :label="`${$t('label.objectType')}:`"
              :disabled="objectDisabledOptions"
              :option-values="objectOptions"
              :option-labels="objectOptionsLables"/>
          </v-col>
          <v-col v-if="objectType !== CONTACT" cols="12">
            <v-text-field
              v-model.trim="input"
              :label="inputLabel"
              :error-messages="[...validationErrors(
                'punycodeDomainName',
                inputErrorMap), ...validationErrors(
                  'input',
                  inputErrorMap)]"/>
          </v-col>
          <template v-if="objectType === CONTACT">
            <v-col cols="12" sm="6">
              <registry-select
                v-model="regType"
                :error-messages="validationErrors(
                  'regType',
                  {
                    required: 'label.errors.enterRegType'
                  })" :label="$t('label.registry')"/>
            </v-col>
            <v-col cols="12" sm="6">
              <contact-handle-select
                v-model="input"
                required
                include-deleted
                :error-messages="validationErrors(
                  'input',
                  inputErrorMap)"
                :disabled="regType === null"
                :registry-id="regType"
                :hint="$t ('label.contactHint')"
                :label="$t ('label.handle')"/>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.reset'" text
          @click="reset"/>
        <v-btn
          v-t="'general.button.submit'" color="primary" :loading="loading"
          type="submit"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {idnDomain, email} from '@/app/validators'
  import {toASCII as punycodeToASCII, toUnicode} from 'punycode/punycode.es6'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import ContactHandleSelect from '@/app/core/components/ContactHandleSelect'

  import RadioGroup from '@/app/core/components/RadioGroup'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  const WDRP = 1
  const ERRP = 2
  const IRTPC = 3
  const WAP = 4

  const DOMAIN = 1
  const EMAIL = 2
  const CONTACT = 3
  const REGADDRESS = 4

  export default {

    components: {
      RadioGroup,
      RegistrySelect,
      ContactHandleSelect
    },

    mixins: [validationMixins],

    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },

    validations () {
      return {
        punycodeDomainName: {
          required,
          ...this.objectType === DOMAIN
            ? {idnDomain}
            : {}
        },
        regType: {
          required: requiredIf (function () {
            return this.objectType === CONTACT
          })
        },
        input: {
          required,
          ...this.objectType === EMAIL || this.objectType === REGADDRESS
            ? {email}
            : {}
        }
      }
    },

    data () {
      return {
        queryType: WDRP,
        objectType: DOMAIN,
        input: '',
        regType: null,

        WDRP,
        ERRP,
        IRTPC,
        WAP,
        DOMAIN,
        EMAIL,
        CONTACT,
        REGADDRESS
      }
    },

    computed: {
      queryOptions () {
        return [WDRP, ERRP, IRTPC, WAP]
      },

      queryOptionsLables () {
        return [
          this.$t ('label.wdrp'),
          this.$t ('label.errp'),
          this.$t ('label.irtpc'),
          this.$t ('label.wap')
        ]
      },

      objectOptions () {
        return [
          DOMAIN,
          this.queryType === WAP ? REGADDRESS : EMAIL,
          CONTACT]
      },

      objectOptionsLables () {
        return [
          this.$t ('label.domain'),
          this.$t ('label.email'),
          this.$t ('label.contact')
        ]
      },

      objectDisabledOptions () {
        switch (this.queryType) {
          case ERRP:
            return [EMAIL, CONTACT, REGADDRESS]
          case WAP:
            return [DOMAIN, CONTACT, EMAIL]
          case IRTPC:
            return [EMAIL, REGADDRESS]
          case WDRP:
            return [CONTACT, REGADDRESS]
          default:
            return []
        }
      },

      inputLabel () {
        switch (this.objectType) {
          case EMAIL:
            return this.$t ('label.email')
          case DOMAIN:
            return this.$t ('label.domain')
          case REGADDRESS:
            return this.$t ('label.regAddress')
          default:
            return this.$t ('label.name')
        }
      },

      inputErrorMap () {
        return {
          required: this.objectType === EMAIL || this.objectType === REGADDRESS
            ? 'label.errors.enterEmail'
            : this.objectType === DOMAIN ? 'label.errors.enterDomain' : 'label.errors.enterHandle',
          ...this.objectType === EMAIL || this.objectType === REGADDRESS
            ? {email: 'label.errors.validEmail'}
            : {idnDomain: 'label.errors.validDomain'}
        }
      },

      punycodeDomainName () {
        return punycodeToASCII (this.input || '')
      }
    },

    watch: {
      queryType (newVal) {
        if (newVal === ERRP) {
          this.objectType = DOMAIN
        } else if (newVal === WDRP &&
          [REGADDRESS, CONTACT].includes (this.objectType)) {
          this.objectType = DOMAIN
        } else if (newVal === IRTPC &&
          [REGADDRESS, EMAIL].includes (this.objectType)) {
          this.objectType = DOMAIN
        } else if (newVal === WAP) {
          this.objectType = REGADDRESS
        }
      },
      '$route.query': {
        handler () {
          if (this.loadParameter ()) {
            this.submit ()
          } else {
            this.$router.replace ({query: { }})
          }
        },
        deep: true
      }
    },

    created () {
      if (this.loadParameter ()) {
        this.submit ()
      } else {
        this.$router.replace ({query: { }})
      }
    },

    methods: {
      setParameter () {
        this.$router.replace ({
          query: {
            service: this.queryType,
            type: this.objectType,
            name: this.input,
            regType: this.regType
          }
        })
      },

      validateParameter (params) {
        const name = params.name
        const type = params.type
        const service = params.service
        const regType = params.regType

        const parsedType = Number.parseInt (type)
        const parsedService = Number.parseInt (service)

        return !!name && [1, 2, 3, 4].includes (parsedType) &&
          [1, 2, 3, 4].includes (parsedService) &&
          (parsedService === 2 ? parsedType !== 2 : true)
          ? {
            name,
            type: parsedType,
            service: parsedService,
            regType: regType
          }
          : false
      },

      loadParameter () {
        const params = this.validateParameter (this.$route.query)

        if (params) {
          this.queryType = params.service
          this.objectType = params.type
          this.input = params.type === DOMAIN ? toUnicode (params.name) : params.name
          this.regType = params.regType
          return true
        }
        return false
      },

      reset () {
        this.queryType = WDRP
        this.objectType = DOMAIN
        this.input = ''
        this.$v.$reset ()
        this.$emit ('reset')
      },

      submit () {
        if (!this.$v.$invalid) {
          this.setParameter ()

          this.$emit ('submit', {
            service: this.queryType,
            type: this.objectType,
            name: this.punycodeDomainName,
            registryType: this.regType
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
