<messages>["./WDRP"]</messages>
<template>
  <v-card>
    <v-card-title>
      <div>
        <div
          v-t="'label.trail'"
          class="text-h5"/>
        <div
          v-t="'label.subTrail'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>
    <v-slide-y-transition mode="out-in">
      <v-card-text v-if="loading" key="loading">
        <v-progress-linear
          indeterminate
          color="primary"/>
      </v-card-text>
      <v-card-text v-else-if="data" key="data">
        <template v-for="(item, idx) in data">
          <audit-trail-item :key="item.objectId" :data="item"/>
          <v-divider v-if="idx< data.length - 1" :key="item.objectId + 'divider'" py-12/>
        </template>
      </v-card-text>
      <v-card-text v-else key="error">
        <v-alert
          v-t="'label.errors.notFound'"
          type="error"/>
      </v-card-text>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
  import AuditTrailItem from './AuditTrailItem'

  export default {

    components: {
      AuditTrailItem
    },

    props: {
      loading: {
        type: Boolean,
        default: false
      },

      error: {
        type: Boolean,
        default: false
      },

      data: {
        type: Array,
        required: false,
        default: null
      }
    }
  }
</script>
